import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useRef, useState } from "react";
import useAppStore from "../../../../hooks/useAppStore";
import { ViziblyTheme } from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import { OriginalRevenueForecastedNumbersModel, OriginalRevenueUnitTypeNumbersModel, useGetRevenueSummaryUnitTypeLazyQuery, OriginalRevenueModel } from "../../../../__generated__/generated_types";
import { useProperties } from "../../../../contexts/properties/PropertiesContext";
import { headerRowNoBottomMargin } from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import * as css from "./revenueSummary.module.scss";
import { MONTHS } from "../../../../constants/Months";
import { formatterDecimalNoGrouping, formatterDollarUSNoDecimal, formatterInt, formatterIntNoGrouping, formatterPercent, formatterPercentNoGrouping } from "../../../../utils/formatters";
import "native-injects";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";
import { IWorkflowPageProps } from "../../logic/workflows";
import { Button } from "@zendeskgarden/react-buttons";
import FileSaver from "file-saver";

function buildLastActualMonthIndex(reforecastStartMonthIndex: number): number {
    let lastActualMonthIndex = reforecastStartMonthIndex - 1;
    if (lastActualMonthIndex < 0) {
        lastActualMonthIndex = 11;
    }

    return lastActualMonthIndex;
}

function buildHeaderCellsCsv(reforecastStartMonthIndex: number, budgetYear: number): string[] {
    let actualLabelYear = budgetYear - 1;
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    if (reforecastStartMonthIndex == 0) {
        actualLabelYear = budgetYear - 2;
    }
    return (
        [
            "",
            `ACTUAL-${actualLabelYear.toString().slice(2)}-${MONTHS[lastActualMonthIndex]}`,
            ...new Array(12 - reforecastStartMonthIndex).fill(null).map((_, index) => (
                `RFCST${index == 0 ? "-" + (budgetYear - 1).toString().slice(2) : ""}-${MONTHS[index + reforecastStartMonthIndex]}`
            )),
            ...new Array(12).fill(null).map((_, index) => (
                `BDGT${index == 0 ? "-" + budgetYear.toString().slice(2) : ""}-${MONTHS[index]}`
            ))
        ]
    );
}

function buildHeaderCells(reforecastStartMonthIndex: number, budgetYear: number): ReactElement[] {
    let actualLabelYear = budgetYear - 1;
    let reforecastLabelYear: number|undefined = undefined;
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    if (reforecastStartMonthIndex == 0) {
        actualLabelYear = budgetYear - 2;
        reforecastLabelYear = budgetYear - 1;
    }

    return (
        [
            <HeaderCell />,
            <HeaderCell >
                <div className={css.headerCellContent}>
                    <span>ACTUAL</span>
                    <span>{actualLabelYear.toString().slice(2)}</span>
                    <span>{MONTHS[lastActualMonthIndex]}</span>
                </div>
            </HeaderCell>,
            ...new Array(12 - reforecastStartMonthIndex).fill(null).map((_, index) => (
                <HeaderCell>
                    <div className={css.headerCellContent}>
                        <span>RFCST</span>
                        <span>{reforecastLabelYear !== undefined && index == 0 ? reforecastLabelYear.toString().slice(2) : ""}</span>
                        <span>{MONTHS[index + reforecastStartMonthIndex]}</span>
                    </div>
                </HeaderCell>
            )),
            ...new Array(12).fill(null).map((_, index) => (
                <HeaderCell>
                    <div className={css.headerCellContent}>
                        <span>BDGT</span>
                        <span>{index == 0 ? budgetYear.toString().slice(2) : ""}</span>
                        <span>{MONTHS[index]}</span>
                    </div>
                </HeaderCell>
            ))
        ]
    );
}

function buildPropertyNumbersSum(
    reforecastStartMonthIndex: number,
    rawNumbersIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    selectedUnitType: string
): number[] {
    const ret = new Array(36).fill(0);
    let rawNumbers = rawNumbersIn;
    if (selectedUnitType !== "all") {
        rawNumbers = rawNumbersIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    for (const row of rawNumbers) {
        for (let i = 0; i < 36; i++) {
            if (i < 12) {
                ret[i] += row.actuals[i];
            }
            else if (i >= 12 && i < 24) {
                ret[i] += row.reforecast[i-12];
            }
            else {
                ret[i] += row.budget[i-24];
            }
        }
    }
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    return ret.slice(0, lastActualMonthIndex + 1).concat(ret.slice(12 + reforecastStartMonthIndex));
}

function buildPropertyNumbersWeightedAverage(
    reforecastStartMonthIndex: number,
    rawNumbersIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    rawWeightsIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    selectedUnitType: string
): number[] {
    let rawNumbers = rawNumbersIn;
    if (selectedUnitType !== "all") {
        rawNumbers = rawNumbersIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    let rawWeights = rawWeightsIn;
    if (selectedUnitType !== "all") {
        rawWeights = rawWeightsIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    const ret = new Array(36).fill(0);
    const rawWeightsById = rawWeights.toIdMap("unitTypeId");
    const weightsSum = new Array(36).fill(0);
    for (const numbersRow of rawNumbers) {
        const weightsRow = rawWeightsById[numbersRow.unitTypeId];
        if (!weightsRow) {
            continue;
        }
        for (let i = 0; i < 36; i++) {

            let num = null;
            let weight = null; weightsRow.actuals[i];
            if (i < 12) {
                num = numbersRow.actuals[i];
                weight = weightsRow.actuals[i];
            }
            else if (i >= 12 && i < 24) {
                num = numbersRow.reforecast[i - 12];
                weight = weightsRow.reforecast[i - 12];
            }
            else {
                num = numbersRow.budget[i - 24];
                weight = weightsRow.budget[i - 24];

            }
            if (num && weight) {
                ret[i] += num * weight;
                weightsSum[i] += weight;
            }
        }
    }
    for (let i = 0; i < 36; i++) {
        if (Math.abs(weightsSum[i]) > 0.0001) {
            ret[i] /= weightsSum[i];
        }
        else {
            ret[i] = 0;
        }
    }

    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    return ret.slice(0, lastActualMonthIndex + 1).concat(ret.slice(12 + reforecastStartMonthIndex));
}

function buildNumbers(reforecastStartMonthIndex: number, rawNumbers: Pick<OriginalRevenueForecastedNumbersModel, "actuals" | "reforecast" | "budget">): (number | null)[] {
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    const ret = rawNumbers.actuals.slice(0, lastActualMonthIndex + 1).concat(rawNumbers.reforecast.slice(reforecastStartMonthIndex)).concat(rawNumbers.budget);
    return ret;
}

function buildRatio(numerator: number [], denominator: number[]): number[] {
    const len = Math.min(numerator.length, denominator.length);
    const ret = new Array(Math.max(numerator.length, denominator.length)).fill(0);

    for (let i = 0; i < len; i++) {
        const d = denominator[i];
        const n = numerator[i];
        if (d && Math.abs(d) > 0.0001 && n) {
            ret[i] = n/d;
        }
    }

    return ret;
}

function buildCells(reforecastStartMonthIndex: number, numbers: (number | null)[], formatter: Intl.NumberFormat): ReactElement[] {
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    const ret = numbers.slice(lastActualMonthIndex)
        .map((val, index, arr) => {
            let className = undefined;
            if (index == 1 || index == arr.length - 12) {
                className = css.periodSplit;
            }
            return (
                <Cell key={index} className={className}>
                    {val !== null ? formatter.format(val) : ""}
                </Cell>
            );
        });
    return ret;
}

function buildCellsCsv(reforecastStartMonthIndex: number, numbers: (number | null)[], formatter: Intl.NumberFormat): string[] {
    const ret = numbers.slice(reforecastStartMonthIndex - 1)
        .map((val) => val !== null ? formatter.format(val) : "");
    return ret;
}

type unitTypeFieldKey = keyof Pick<OriginalRevenueModel,
    "unitTypeAvgMarketRent" |
    "unitTypeExpirationCount" |
    "unitTypeInPlaceRent" |
    "unitTypeMoveInCount" |
    "unitTypeMoveOutCount" |
    "unitTypeNewLeaseTradeOut" |
    "unitTypeOccupiedCount" |
    "unitTypeRenewalCount" |
    "unitTypeRenewalTradeOut" |
    "unitTypeUnitCountMonthly" |
    "unitTypeVacancyCount"
>;

export function RevenueSummaryV2(props: IWorkflowPageProps): ReactElement {
    const { currentProperty: property } = useProperties();
    const [loadDataUnitType, { data: unitTypeData, loading: unitTypeLoading }] = useGetRevenueSummaryUnitTypeLazyQuery({ fetchPolicy: "no-cache" });
    const [unitTypes, setUnitTypes] = useState<{ unitTypeId: string, unitTypeName: string }[]>();
    const [selectedUnitType, setSelectedUnitType] = useState<string>("all");
    const tableRef = useRef<HTMLTableElement>(null);
    const [expanded, setExpanded] = useState<Record<unitTypeFieldKey, boolean>>({
        unitTypeAvgMarketRent: false,
        unitTypeExpirationCount: false,
        unitTypeInPlaceRent: false,
        unitTypeMoveInCount: false,
        unitTypeMoveOutCount: false,
        unitTypeNewLeaseTradeOut: false,
        unitTypeOccupiedCount: false,
        unitTypeRenewalCount: false,
        unitTypeRenewalTradeOut: false,
        unitTypeUnitCountMonthly: false,
        unitTypeVacancyCount: false,
    });
    const appStore = useAppStore();

    useEffect(() => {
        if (!property) {
            return;
        }

        setUnitTypes(undefined);
        setSelectedUnitType("all");
        loadDataUnitType({
            variables: {
                propertyId: property.id,
                budgetYear: property.budgetYear
            }
        });
    }, [property]);

    useEffect(() => {
        if (!unitTypeData || unitTypeLoading || !unitTypeData.originalRevenueModel) {
            return;
        }
        // assuming all metrics have same unit types list
        setUnitTypes(
            [{ unitTypeId: "all", unitTypeName: "All Unit Types" },
            ...Object.entries(unitTypeData
                .originalRevenueModel
                .unitTypeUnitCount
                .toIdMap("unitTypeId", "unitTypeName")
            )
                .map(([unitTypeId, unitTypeName]) => ({ unitTypeId, unitTypeName }))
                .sortBy("unitTypeName")
            ]
        );
    }, [unitTypeData, unitTypeLoading]);


    useEffect(() => {
        appStore.set({ isLoading: false });
    }, []);

    function handleExpand(key: unitTypeFieldKey) {
        setExpanded(prev => {
            const prevVal = prev[key];
            return {
                ...prev,
                [key]: !prevVal
            };
        });
    }

    function tableBlockRowRatioCsv({
            label,
            unitTypeNumeratorField,
            formatter,
            unitTypeDenominatorField
        }: {
            label: string,
            unitTypeNumeratorField: unitTypeFieldKey,
            formatter: Intl.NumberFormat,
            unitTypeDenominatorField: unitTypeFieldKey
    }): string[][] {
        const revenueData = unitTypeData?.originalRevenueModel;
        if (!property || !revenueData) {
            return [];
        }

        let propertyNumbers: number[] = [];
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeNumeratorField],
                selectedUnitType);
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeDenominatorField],
                selectedUnitType);
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        return (
                [
                    [label,
                        ...buildCellsCsv(
                            property.reforecastStartMonthIndex,
                            propertyNumbers,
                            formatter
                        )
                    ]
                ]
        );
    }

    function TableBlockRowRatio({
            label,
            unitTypeNumeratorField,
            formatter,
            unitTypeDenominatorField
    }:
    {
        label: string,
        unitTypeNumeratorField: unitTypeFieldKey,
        formatter: Intl.NumberFormat,
        unitTypeDenominatorField: unitTypeFieldKey
    }
    ): ReactElement {
        const revenueData = unitTypeData?.originalRevenueModel;
        if (!property || !revenueData) {
            return <></>;
        }

        let propertyNumbers: number[] = [];
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeNumeratorField],
                selectedUnitType);
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeDenominatorField],
                selectedUnitType);
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        return (
                <Row>
                    <Cell>{label}</Cell>
                    {buildCells(
                        property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )}
                </Row>
        );
    }


    function tableBlockRowExpandableCsv({
            label,
            unitTypeField,
            formatter,
            propertyAggregate = "sum",
            unitTypeFieldWeights
    }:
    {
        label: string,
        unitTypeField: unitTypeFieldKey,
        formatter: Intl.NumberFormat,
        propertyAggregate?: "sum" | "wavg" | "ratio",
        unitTypeFieldWeights?: unitTypeFieldKey
    }
    ): string[][] {
        const revenueData = unitTypeData?.originalRevenueModel;
        if (!property || !revenueData) {
            return [];
        }

        let propertyNumbers: number[] = [];
        if (propertyAggregate == "sum") {
            propertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
        }
        else if (propertyAggregate == "wavg" && unitTypeFieldWeights) {
            propertyNumbers = buildPropertyNumbersWeightedAverage(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
        }
        else if (propertyAggregate == "ratio" && unitTypeFieldWeights) {
            const numeratorPropertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
            const denominatorPropertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
            propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        }
        else {
            return [];
        }
        return (
            [
                [
                    label,
                    ...buildCellsCsv(
                        property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )
                ],
                ...revenueData[unitTypeField]
                    .filter(utData => selectedUnitType == "all" || utData.unitTypeId == selectedUnitType)
                    .sortBy("unitTypeName").map(utData =>
                        [
                            ` ${utData.unitTypeName}`,
                            ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, utData), formatter)
                        ]
                    )
            ]
        );
    }

    function TableBlockRowExpandable({
            label,
            unitTypeField,
            formatter,
            propertyAggregate = "sum",
            unitTypeFieldWeights
    }:
    {
        label: string,
        unitTypeField: unitTypeFieldKey,
        formatter: Intl.NumberFormat,
        propertyAggregate?: "sum" | "wavg" | "ratio",
        unitTypeFieldWeights?: unitTypeFieldKey
    }
    ): ReactElement {
        const revenueData = unitTypeData?.originalRevenueModel;
        if (!property || !revenueData) {
            return <></>;
        }

        let propertyNumbers: number[] = [];
        if (propertyAggregate == "sum") {
            propertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
        }
        else if (propertyAggregate == "wavg" && unitTypeFieldWeights) {
            propertyNumbers = buildPropertyNumbersWeightedAverage(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
        }
        else if (propertyAggregate == "ratio" && unitTypeFieldWeights) {
            const numeratorPropertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
            const denominatorPropertyNumbers = buildPropertyNumbersSum(
                    property.reforecastStartMonthIndex,
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
            propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        }
        else {
            return <></>;
        }
        return (
            <>
                <Row>
                    <Cell onClick={() => handleExpand(unitTypeField)}>
                        {expanded[unitTypeField] ? "-" : "+"} {label}
                    </Cell>
                    {buildCells(
                        property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )}
                </Row>
                {
                    expanded[unitTypeField] &&
                    revenueData[unitTypeField]
                        .filter(utData => selectedUnitType == "all" || utData.unitTypeId == selectedUnitType)
                        .sortBy("unitTypeName").map(utData =>
                            <Row>
                                <Cell className={css.unitTypeName}> {utData.unitTypeName}</Cell>
                                {buildCells(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, utData), formatter)}
                            </Row>
                        )
                }
            </>
        );
    }

    function SubHead(props: {title: string}): ReactElement {
        if (!property) {
            return <></>;
        }
        return (
            <Row>
                <Cell colSpan={24 - property.reforecastStartMonthIndex + 2} style={{ textAlign: "center", backgroundColor: "var(--grey-50)" }}> {props.title} </Cell>
            </Row>
        );
    }

    function exportToCsv(): void {
        if (!property || !unitTypeData || !unitTypeData.originalRevenueModel) {
            return;
        }
        const csvRows = [
            buildHeaderCellsCsv(property.reforecastStartMonthIndex, property.budgetYear).join(",")
        ];

        function toString(arr: string[]): string {
            return arr.join(",");
        }
        csvRows.push("METRICS");
        csvRows.push(...tableBlockRowExpandableCsv({label: "Unit Count", unitTypeField: "unitTypeUnitCountMonthly", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Expiration Count", unitTypeField: "unitTypeExpirationCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowRatioCsv({label: "Occupied %", unitTypeNumeratorField: "unitTypeOccupiedCount", unitTypeDenominatorField: "unitTypeUnitCountMonthly", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Occupied Count", unitTypeField: "unitTypeOccupiedCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowRatioCsv({label: "Renewal %", unitTypeNumeratorField: "unitTypeRenewalCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Renewal Count", unitTypeField: "unitTypeRenewalCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Move In Count", unitTypeField: "unitTypeMoveInCount", formatter: formatterDecimalNoGrouping}).map(v => toString(v)));
        csvRows.push(["Total Move In Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.totalMoveInCount), formatterDecimalNoGrouping)].join(","));
        csvRows.push(...tableBlockRowRatioCsv({label: "Move Out %", unitTypeNumeratorField: "unitTypeMoveOutCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Move Out Count", unitTypeField: "unitTypeMoveOutCount", formatter: formatterDecimalNoGrouping}).map(v => toString(v)));
        csvRows.push(["Early Term Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.earlyTerminationCount), formatterIntNoGrouping)].join(","));
        csvRows.push(["MTM Move Out Count",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.m2mMoveOutCount),formatterIntNoGrouping)].join(","));
        csvRows.push(["Total Move Out Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.totalMoveOutCount), formatterDecimalNoGrouping)].join(","));
        csvRows.push("AMOUNTS ($)");
        csvRows.push(...tableBlockRowExpandableCsv({label: "Avg Market Rent", unitTypeField: "unitTypeAvgMarketRent", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "In Place Rent", unitTypeField: "unitTypeInPlaceRent", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "New Lease Tradeout", unitTypeField: "unitTypeNewLeaseTradeOut", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeMoveInCount"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Renewal Tradeout", unitTypeField: "unitTypeRenewalTradeOut", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeRenewalCount"}).map(v => toString(v)));
        csvRows.push("FINANCIALS ($)");
        csvRows.push(["Market Rent",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.rent),formatterIntNoGrouping)].join(","));
        csvRows.push(["Loss To Lease",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.lossToLease),formatterIntNoGrouping)].join(","));
        csvRows.push(["Gross Potential Rent",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.grossPotentialRent),formatterIntNoGrouping)].join(","));
        csvRows.push(["GPR Change",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.momLossToLeaseChange),formatterIntNoGrouping)].join(","));

        const saveData = new Blob([csvRows.join("\n")], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(saveData, `${property.budgetYear} - ${property.name}.csv`);
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={workflowCSS.workflowContainer}>
                <WorkflowHeader className={headerRowNoBottomMargin}>
                    <WorkflowHeader.LeftCol>
                        <div className={workflowHeaderCSS.rowItemsContainer}>
                            <WorkflowNavToggle />
                            <WorkflowPageLabel label="Revenue Summary" />
                            {unitTypes &&
                                <>
                                    <div className={css.unitTypeSelector}>
                                        <Dropdown
                                            selectedItem={selectedUnitType}
                                            onSelect={(v) => setSelectedUnitType(v)}
                                        >
                                            <Field>
                                                <Select>
                                                    {unitTypes.find(ut => ut.unitTypeId == selectedUnitType)?.unitTypeName ?? "N/A"}
                                                </Select>
                                            </Field>
                                            <Menu>
                                                {unitTypes.map(({ unitTypeId, unitTypeName }) => (
                                                    <Item key={unitTypeId} value={unitTypeId}>
                                                        {unitTypeName}
                                                    </Item>
                                                ))}
                                            </Menu>
                                        </Dropdown>
                                    </div>
                                    <Button onClick={() => exportToCsv()}>Export</Button>
                                </>
                            }
                        </div>
                    </WorkflowHeader.LeftCol>
                    <WorkflowHeader.RightCol>
                        <div className={workflowHeaderCSS.columnItemsContainer}>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick} />
                        </div>
                    </WorkflowHeader.RightCol>
                </WorkflowHeader>
                <div className={`${workflowCSS.bodyContainer} ${css.revenueSummary}`}>
                    {unitTypeData && unitTypeData.originalRevenueModel && !unitTypeLoading && property && unitTypes &&
                        <>
                            <Table ref={tableRef}>
                                <Head isSticky>
                                    <HeaderRow>
                                        {buildHeaderCells(property.reforecastStartMonthIndex, property.budgetYear)}
                                    </HeaderRow>
                                </Head>
                                <Body>
                                    <SubHead title="METRICS"/>
                                    <TableBlockRowExpandable
                                        key={"Unit Count"}
                                        label={"Unit Count"}
                                        unitTypeField={"unitTypeUnitCountMonthly"}
                                        formatter={formatterInt}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Expiration Count"}
                                        label={"Expiration Count"}
                                        unitTypeField={"unitTypeExpirationCount"}
                                        formatter={formatterInt}
                                    />
                                    <TableBlockRowRatio
                                        key="Occupied %"
                                        label="Occupied %"
                                        unitTypeNumeratorField="unitTypeOccupiedCount"
                                        unitTypeDenominatorField="unitTypeUnitCountMonthly"
                                        formatter={formatterPercent}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Occupied Count"}
                                        label={"Occupied Count"}
                                        unitTypeField={"unitTypeOccupiedCount"}
                                        formatter={formatterInt}
                                    />
                                    <TableBlockRowRatio
                                        key="Renewal %"
                                        label="Renewal %"
                                        unitTypeNumeratorField="unitTypeRenewalCount"
                                        unitTypeDenominatorField="unitTypeExpirationCount"
                                        formatter={formatterPercent}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Renewal Count"}
                                        label={"Renewal Count"}
                                        unitTypeField={"unitTypeRenewalCount"}
                                        formatter={formatterInt}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Move In Count"}
                                        label={"Move In Count"}
                                        unitTypeField={"unitTypeMoveInCount"}
                                        formatter={formatterInt}
                                    />
                                    <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                        <Cell>Total Move In Count</Cell>
                                        {buildCells(property.reforecastStartMonthIndex,
                                                    buildNumbers(
                                                            property.reforecastStartMonthIndex,
                                                            unitTypeData.originalRevenueModel.totalMoveInCount),
                                                    formatterInt
                                                    )
                                        }
                                    </Row>
                                    <TableBlockRowRatio
                                        key="Move Out %"
                                        label="Move Out %"
                                        unitTypeNumeratorField="unitTypeMoveOutCount"
                                        unitTypeDenominatorField="unitTypeExpirationCount"
                                        formatter={formatterPercent}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Move Out Count"}
                                        label={"Move Out Count"}
                                        unitTypeField={"unitTypeMoveOutCount"}
                                        formatter={formatterInt}
                                    />
                                    <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                        <Cell>Early Term Count</Cell>
                                        {buildCells(property.reforecastStartMonthIndex,
                                                    buildNumbers(
                                                            property.reforecastStartMonthIndex,
                                                            unitTypeData.originalRevenueModel.earlyTerminationCount),
                                                    formatterInt
                                                    )
                                        }
                                    </Row>
                                    <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                        <Cell>MTM Move Out Count</Cell>
                                        {buildCells(property.reforecastStartMonthIndex,
                                                    buildNumbers(
                                                            property.reforecastStartMonthIndex,
                                                            unitTypeData.originalRevenueModel.m2mMoveOutCount),
                                                    formatterInt
                                                    )
                                        }
                                    </Row>
                                    <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                        <Cell>Total Move Out Count</Cell>
                                        {buildCells(property.reforecastStartMonthIndex,
                                                    buildNumbers(
                                                            property.reforecastStartMonthIndex,
                                                            unitTypeData.originalRevenueModel.totalMoveOutCount),
                                                    formatterInt
                                                    )
                                        }
                                    </Row>

                                    <SubHead title="AMOUNTS"/>

                                    <TableBlockRowExpandable
                                        key={"Avg Market Rent"}
                                        label={"Avg Market Rent"}
                                        unitTypeField={"unitTypeAvgMarketRent"}
                                        formatter={formatterDollarUSNoDecimal}
                                        propertyAggregate="wavg"
                                        unitTypeFieldWeights={"unitTypeUnitCountMonthly"}
                                    />
                                    <TableBlockRowExpandable
                                        key={"In Place Rent"}
                                        label={"In Place Rent"}
                                        unitTypeField={"unitTypeInPlaceRent"}
                                        formatter={formatterDollarUSNoDecimal}
                                        propertyAggregate="wavg"
                                        unitTypeFieldWeights={"unitTypeUnitCountMonthly"}
                                    />
                                    <TableBlockRowExpandable
                                        key={"New Lease Tradeout"}
                                        label={"New Lease Tradeout"}
                                        unitTypeField={"unitTypeNewLeaseTradeOut"}
                                        formatter={formatterDollarUSNoDecimal}
                                        propertyAggregate="wavg"
                                        unitTypeFieldWeights={"unitTypeMoveInCount"}
                                    />
                                    <TableBlockRowExpandable
                                        key={"Renewal Tradeout"}
                                        label={"Renewal Tradeout"}
                                        unitTypeField={"unitTypeRenewalTradeOut"}
                                        formatter={formatterDollarUSNoDecimal}
                                        propertyAggregate="wavg"
                                        unitTypeFieldWeights={"unitTypeRenewalCount"}
                                    />

                                    <SubHead title="FINANCIALS"/>

                                    <Row>
                                        <Cell>Market Rent</Cell>
                                        {buildCells(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.rent), formatterDollarUSNoDecimal)}
                                    </Row>
                                    <Row>
                                        <Cell>Loss To Lease</Cell>
                                        {buildCells(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.lossToLease), formatterDollarUSNoDecimal)}
                                    </Row>
                                    <Row>
                                        <Cell>Gross Potential Rent</Cell>
                                        {buildCells(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.grossPotentialRent), formatterDollarUSNoDecimal)}
                                    </Row>
                                    <Row style={{fontStyle: "italic"}}>
                                        <Cell>GPR Change</Cell>
                                        {buildCells(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.originalRevenueModel.momLossToLeaseChange), formatterDollarUSNoDecimal)}
                                    </Row>
                                </Body>
                            </Table>
                        </>
                    }
                </div>
            </div>
        </ThemeProvider>
    );
}