import { ReactElement, useEffect, useMemo, useState } from "react";
import useAppStore from "../../hooks/useAppStore";
import {ThemeProvider} from "styled-components";
import { ViziblyTheme } from "../../styles/zendesk-garden/ViziblyZDGTheme";
import * as adminCSS from "../admin/styles/adminStyles.module.scss";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import AdminHeader from "../admin/AdminHeader";
import { Button } from "@zendeskgarden/react-buttons";
import { PropertyBudgetCompletionModel, useGetPropertyBudgetCompletionsLazyQuery, useSetPropertyBudgetCompletionMutation } from "../../__generated__/generated_types";
import * as css from "./budgetCompletion.module.scss"
import Card from "../simplified-revenue/components/Card";
import { Field, MediaInput } from "@zendeskgarden/react-forms";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Dropdown, Item, Menu, Select, Field as DropdownField } from "@zendeskgarden/react-dropdowns";
import { formatterPercent } from "../../utils/formatters";

export function PropertyBudgetCompletion(): ReactElement {
    const appStore = useAppStore();
    const [fetchBudgetStatus, {data, loading}] = useGetPropertyBudgetCompletionsLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [setBudgetStatus, {loading: updating}] = useSetPropertyBudgetCompletionMutation({
        onCompleted: () => {
            fetchBudgetStatus();
        }
    });
    const [parsedData, setParsedData] = useState<PropertyBudgetCompletionModel[]>();
    const [filteredData, setFilteredData] = useState<PropertyBudgetCompletionModel[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<"Any Status" | "In Progress" | "Completed">("Any Status");

    useEffect(
        () => {
            appStore.set({isLoading: false});
            fetchBudgetStatus();
        },
        []
    );

    useEffect(() => {
        if (data && !loading) {
            const parsed = data
                .queryPropertyBudgetCompletions
                .sortBy("propertyName")
                .map(r => ({
                    propertyId: r.propertyId,
                    propertyName: r.propertyName,
                    budgetYear: r.budgetYear,
                    completed: r.completed,
                    updatedAt: r.updatedAt
                }));

            setParsedData(parsed);
            updateFilteredData(parsed);
        }
    }, [data, loading]);

    useEffect(() => {
        updateFilteredData(parsedData ?? []);
    }, [searchString, selectedStatus]);

    function updateFilteredData(parsedData: PropertyBudgetCompletionModel[]) {
        let filtered = parsedData.filter(
                p => selectedStatus === "Any Status" ||
                selectedStatus == "Completed" && p.completed ||
                selectedStatus == "In Progress" && !p.completed);
        if (searchString && searchString.trim() !== "") {
            filtered = filtered.filter(p => p.propertyName.toLowerCase().includes(searchString));
        }
        setFilteredData(filtered);
    }

    function handleCompletion(propertyId: string, budgetYear: number, completed: boolean) {
        setBudgetStatus({
            variables: {
                propertyId: propertyId,
                budgetYear: budgetYear,
                completed: completed
            }
        });
    }

    const doneRatioMsg = useMemo(() => {
        let ret = undefined;
        if (parsedData && parsedData.length > 1) {
            const completedCount = parsedData.filter(d => d.completed).length;
            ret = `Completed ${completedCount} out of ${parsedData.length} (${formatterPercent.format(completedCount / parsedData.length)})`;
        }
        return ret;
    }, parsedData);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={adminCSS.adminWrapper}>
                <Row>
                    <Col>
                        <AdminHeader
                            title={"Budget Completion"}
                            subtitle={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{marginBottom: "1rem"}}>
                            This tells others in the company that budgeting for the property has been completed or is still in progress
                        </div>
                        <Card
                            title={`Property Budget Status. ${doneRatioMsg ?? ""}`}
                            actions={
                                (parsedData?.length ?? 0) > 5 ?
                                <>
                                    <Dropdown
                                        selectedItem={selectedStatus ?? "Any Status"}
                                        onSelect={setSelectedStatus}
                                    >
                                        <DropdownField className={css.statusFilter}>
                                            <Select>
                                                {selectedStatus ?? "Any Status"}
                                            </Select>
                                        </DropdownField>
                                        <Menu>
                                            <Item key={"Any Status"} value={"Any Status"}>
                                                Any Status
                                            </Item>
                                            <Item key={"In Progress"} value={"In Progress"}>
                                                In Progress
                                            </Item>
                                            <Item key={"Completed"} value={"Completed"}>
                                                Completed
                                            </Item>
                                        </Menu>
                                    </Dropdown>

                                    <Field className={css.propertySearch}>
                                        <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => setSearchString(e.target.value.trim().toLowerCase())}/>
                                    </Field>
                                </>
                                :
                                undefined
                            }
                        >
                            <div className={css.propertyTableWrapper}>
                                <table className={css.propertyTable}>
                                    <thead>
                                        <tr className={css.headerCell}>
                                            <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                            <th className={`${css.cell} ${css.periodCell}`}>Status</th>
                                            <th className={`${css.cell} ${css.periodCell}`}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {filteredData.map(property => (
                                                <tr key={property.propertyId}>
                                                    <td className={`${css.cell}`}>{ property.propertyName }</td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>{property.completed ? "Completed" : "In Progress"}</td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>
                                                        <Button
                                                            disabled={loading || updating}
                                                            onClick={() => handleCompletion(property.propertyId, property.budgetYear, !property.completed)}
                                                        >
                                                            {property.completed ? "Set In Progress" : "Mark Completed"}
                                                        </Button>
                                                    </td>
                                                </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}