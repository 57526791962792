import React, {useContext} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ChangePassword from '../../components/auth/ChangePassword';
import NavBar from '../../components/navbar/NavBar';
import AdminStart from '../../pages/admin/admin-start/AdminStart';
import Dashboard from '../../pages/dashboard/Dashboard';
import StartPlanning from '../../pages/start-planning/StartPlanning';
import {useRoutingUtils} from '../RoutingUtils';
import {AdminRoute} from "../AdminRoute";
import {useSettings} from '../../contexts/settings/SettingsContext';
import PerformanceMetrics from "../../pages/performance-metrics/PerformanceMetrics";
import {AuthorizationContext} from "../../authorization/AuthorizationContext";
import AnalystDashboard from "../../pages/analyst/AnalystDashboard";
import BasicSetUp from "../../pages/setup/basic-setup/BasicSetUp";
import DefineBudget from "../../pages/setup/define-budget/DefineBudget";
import BudgetCategories from "../../pages/setup/budget-categories/BudgetCategories";
import {BudgetingModel} from "../../pages/experimental-excel/BudgetingModel";
import {BudgetingModelMulti} from "../../pages/experimental-excel/BudgetingModelMulti";
import {AccountRoute, AccountRouteParentComponents} from "./account/AccountRoute";
import UnitLevelModeling from "../../pages/unit-level-modeling/UnitLevelModeling";
import {FileUploadCreate} from "../../pages/admin/file-upload/FileUploadCreate";
import ReportsRoute from "../../pages/reports/ReportsRoute";
import {LineItemsReport} from '../../pages/reports/line-items-report/LineItemsReport';
import {FeatureFlagContext} from '../../feature-flag/FeatureFlagContextProvider';
import {Feature} from '../../feature-flag/Feature';
import PlanningDashboard from "../../pages/planning-dashboard/PlanningDashboard";
import PropertyDrivers from "../../pages/property-drivers/PropertyDrivers";
import TopCardTagSettings from '../../pages/admin/top-card-tag-settings/TopCardTagSettings';
import {AuthContext, Role} from '../../contexts/AuthContext';
import FinancialReportsBulkExport from '../../pages/bulk-export/FinancialReportsBulkExport';
import OperationalReportsBulkExport from '../../pages/bulk-export/OperationalReportsBulkExport';
import BulkUpdate from '../../pages/bulk-update/BulkUpdate';
import BudgetSeasonManagement from "../../pages/budget-season-management/BudgetSeasonManagement";
import COAManagement from "../../pages/coa-management/COAManagement";
import UnitTypeManagement from "../../pages/unit-type-management/UnitTypeManagement";
import {COATags} from "../../pages/coa-tags/COATags";
import {CircularReferences} from "../../pages/circular-references/CircularReferences";
import {CustomItems} from "../../pages/custom-items/CustomItems";
import {MixPanelRouteWatcher} from "../../mixpanel/MixPanelRouteWatcher";
import {PropertyBudgetCompletion} from "../../pages/budget-completion/PropertyBudgetCompletion";
import {Users} from "../../pages/admin/users/Users";

{/*
    TODO: Normalize to a correct top-level ThemeProvider (https://vizibly.atlassian.net/browse/APP-922), then wrap
    main route and remove ThemeProviders down in the code (unless rare cases when they're necessary)
*/}

export interface MainRouteProps {
    signedIn?: () => void
}
export const MainRoute: React.FC<MainRouteProps> = (params: MainRouteProps) =>
    <Router>
        <MixPanelRouteWatcher />
        <Contents {...params}></Contents>
    </Router>;

const Contents: React.FC<MainRouteProps> = () => {
    const {goto} = useRoutingUtils();
    const {hasReports} = useSettings();
    const {hasAuthorization, hasOnlyAuthorization} = useContext(AuthorizationContext);
    const {hasFeature} = useContext(FeatureFlagContext);
    const {role} = useContext(AuthContext);

    return (
        <div className="d-flex flex-column h-100">
            <NavBar
                needSettings={goto('/admin/start')}
                needPlan={goto('/setup/start')}
            />
            {
                <Switch>
                    <Route exact path="/auth/changepassword" component={ChangePassword} />
                    <AdminRoute exact path="/admin/start" component={() =>
                        <AdminStart
                            loggedOut={() => goto('/')}
                        />
                    } />
                    <Route exact path="/dashboard" component={() => <Dashboard />} />
                    <Route exact path="/line_items" component={() => <LineItemsReport />} />
                    <Route exact path="/performance" component={PerformanceMetrics} />
                    <Route exact path="/hub/:tab?" component={AnalystDashboard} />
                    <Route exact path="/revenue/:tab?/:type?" component={UnitLevelModeling} />
                    <AdminRoute exact path="/admin/setup/basic" component={BasicSetUp} />
                    <AdminRoute exact path="/admin/setup/budget" component={DefineBudget} />
                    <AdminRoute exact path="/admin/setup/categories" component={BudgetCategories} />
                    <AdminRoute exact path="/admin/setup/experimental" component={BudgetingModel} />
                    <AdminRoute exact path="/admin/setup/experimental-multi" component={BudgetingModelMulti} />
                    <AdminRoute exact path="/admin/users" component={Users} />
                    <AdminRoute exact path="/admin/file-upload/create" component={FileUploadCreate} />
                    <Route path="/account/:id/:type">
                        <AccountRoute parentPath={AccountRouteParentComponents.ACCOUNT} />
                    </Route>
                    {
                        /**
                         * 1. The original and unit level models use the normal operational routes.
                         * 2. The simplified model uses the new simplified-revenue routes.
                         */
                    }
                    <Route path="/operational/:page/:type" component={() =>
                        <AccountRoute parentPath={AccountRouteParentComponents.OPERATIONAL} />
                    } />
                    {/* <Route path="/rs" component={() =>
                        <RevenueSummaryV2/>
                    } /> */}

                    <Route path="/renovations/:page/:type" component={() =>
                        <AccountRoute parentPath={AccountRouteParentComponents.RENOVATIONS} />
                    } />

                    <Route path="/simplified-revenue/:page" component={() =>
                        <AccountRoute parentPath={AccountRouteParentComponents.SIMPLIFIED_REVENUE} />
                    } />

                    <Route path="/payroll" component={() =>
                        <AccountRoute parentPath={AccountRouteParentComponents.PAYROLL} />
                    } />

                    <Route path="/circular-references">
                        <CircularReferences/>
                    </Route>

                    <Route path="/budget-completion">
                        <PropertyBudgetCompletion/>
                    </Route>

                    {hasFeature(Feature.MVR) &&
                        <Route path="/reports/mvr">
                            <ReportsRoute />
                        </Route>
                    }

                    <Route path="/bulk-export/financial-reports" component={() =>
                        <FinancialReportsBulkExport />
                    } />

                    <Route path="/bulk-export/operational-reports" component={() =>
                        <OperationalReportsBulkExport />
                    } />

                    {/* TODO remove feature check and ternary here when StartPlanning page is fully deprecated  */}
                    <Route
                        exact
                        path="/setup/start"
                        component={hasFeature(Feature.PlanningDashboard) ? PlanningDashboard : StartPlanning}
                    />

                    {role === Role.Admin &&
                        <Route path="/settings/financial-metrics-management">
                            <TopCardTagSettings />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/property-plan-management">
                            <PropertyDrivers />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/lock-management">
                            <BulkUpdate />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/budget-season-management">
                            <BudgetSeasonManagement />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/coa-management">
                            <COAManagement />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/account-tags-management">
                            <COATags />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/custom-item-management">
                            <CustomItems />
                        </Route>
                    }

                    {role === Role.Admin &&
                        <Route path="/settings/unit-type-management">
                            <UnitTypeManagement />
                        </Route>
                    }

                    <Route exact path="/">
                        <Redirect to="/setup/start" />
                    </Route>

                    <Route path="*">
                        <Redirect to="/setup/start" />
                    </Route>
                </Switch>
            }
        </div>
    );
};
